import React, { useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { useFetchCurrentUserQuery } from '../../../api/userApi';
import { useSelector } from 'react-redux';
import { selectCurrentUserRoles } from '../../../redux/selectors';

const ProfilePage = () => {
    const { data: currentUser, isLoading, error, refetch } = useFetchCurrentUserQuery();
    const roles = useSelector(selectCurrentUserRoles);

    // Bei jedem Aufruf der Komponente einen erneuten Request auslösen
    useEffect(() => {
        refetch(); // Löst die Query erneut aus
    }, [refetch]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (error || !currentUser) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography variant="h6" color="error">
                    Error loading user data. Please try again.
                </Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    User Details
                </Typography>
                <Typography variant="body1"><strong>ID:</strong> {currentUser.id}</Typography>
                <Typography variant="body1"><strong>UID:</strong> {currentUser.uid}</Typography>
                <Typography variant="body1"><strong>Email:</strong> {currentUser.email}</Typography>
                <Typography variant="body1"><strong>Roles:</strong> {roles.join(', ')}</Typography>

                {/* Conditionally render based on roles */}
                {roles.includes('SUPER_ADMIN') && (
                    <Typography variant="body1"><strong>Admin Access:</strong> Granted</Typography>
                )}
            </Paper>
        </Box>
    );
};

export default ProfilePage;