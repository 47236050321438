import React from "react";
import { Typography, Card } from "@mui/material";

const DeepDiveSlide = ({ company }) => {
    if (!company || !company.deepDive) {
        // Falls keine Deep-Dive-Daten vorhanden sind, wird nichts gerendert
        return null;
    }

    return (
        <Card
            sx={{
                mt: 4,
                p: 4,
                bgcolor: "background.paper",
                border: "1px solid",
                borderColor: "grey.300",
                borderRadius: "8px",
            }}
        >
            <Typography
                variant="h5"
                sx={{ color: "primary.main", mb: 2 }}
            >
                Deep Dive: {company.name}
            </Typography>
            <Typography variant="body1">{company.deepDive.notes}</Typography>
        </Card>
    );
};

export default DeepDiveSlide;