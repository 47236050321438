import React from "react";
import { Box, Typography, Card, List, ListItem, ListItemText } from "@mui/material";
import Grid from "@mui/material/Grid";

const ProfileSlide = ({ company }) => {
    if (!company) {
        return (
            <Typography variant="h6" sx={{ color: "error.main" }}>
                No company data available.
            </Typography>
        );
    }

    return (
        <Card sx={{ p: 4, bgcolor: "background.paper", width: '100%' }}>
            {/* Header */}
            <Box sx={{ mb: 4 }}>
                <Typography variant="h3" sx={{ color: "primary.main", fontWeight: "bold" }}>
                    {company.name || "The i-engineers AG"}
                </Typography>
            </Box>
            {/* Logo */}
            <Box sx={{
                width: '60px',
                height: '60px',
            }}>
                <img
                    src={company.logo}
                    alt={company.name}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain'
                    }}
                />
            </Box>

            {/* Company Description Box */}
            <Box sx={{
                mb: 4,
                p: 3,
                bgcolor: 'background.paper',
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'grey.300'
            }}>
                <Typography variant="body1">
                    {company.description || "The i-engineers AG is a prominent Swiss company based in Zurich, specializing in the HealthTech industry. It focuses on developing innovative software solutions aimed at digitalizing hospital processes and enhancing healthcare efficiency. The company is a key player in the health informatics community, driving digital transformation across the healthcare sector."}
                </Typography>
            </Box>

            {/* Main Content Grid */}
            <Grid container spacing={4} sx={{ minHeight: '600px' }}>
                {/* Linke Spalte - Produktinformationen */}
                <Grid item xs={6}>
                    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>

                        {/* Produktdetails */}
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            {[
                                { title: "What is the product?", content: company.product },
                                { title: "How does it work?", content: company.howItWorks },
                                { title: "Who is the user?", content: company.users },
                                { title: "What is the benefit?", content: company.benefits },
                            ].map((item, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        mb: index !== 3 ? 2 : 0,
                                        bgcolor: "secondary.main",
                                        color: "secondary.contrastText",
                                        p: 2,
                                        borderRadius: 2,
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2">{item.content || "No information available."}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Grid>

                {/* Rechte Spalte */}
                <Grid item xs={6}>
                    <Box sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        gap: 3
                    }}>

                        {/* Market Analysis */}
                        <Box sx={{
                            p: 3,
                            borderRadius: 4,
                            bgcolor: 'rgb(178, 235, 242)',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2
                        }}>
                            <Typography variant="h6" sx={{
                                color: "primary.main",
                                fontWeight: "bold",
                                textAlign: 'center'
                            }}>
                                Market Analysis
                            </Typography>
                            {[
                                { label: 'TAM (Total Addressable Market)', value: company.tam || '€500M' },
                                { label: 'SAM (Serviceable Available Market)', value: company.sam || '€200M' },
                                { label: 'SOM (Serviceable Obtainable Market)', value: company.som || '€50M' }
                            ].map((item, index) => (
                                <Box key={index} sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    p: 1,
                                    borderBottom: index !== 2 ? '1px solid rgba(0,0,0,0.1)' : 'none'
                                }}>
                                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                        {item.label}
                                    </Typography>
                                    <Typography variant="body2" sx={{
                                        fontWeight: 'bold',
                                        color: 'primary.main'
                                    }}>
                                        {item.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                        {/* Collaboration Options */}
                        <Box sx={{
                            mt: 'auto',
                            p: 3,
                            borderRadius: 4,
                            bgcolor: 'rgb(178, 235, 242)',
                            width: '100%'
                        }}>
                            <Typography variant="h6" sx={{
                                color: "primary.main",
                                fontWeight: "bold",
                                mb: 2,
                                textAlign: 'center'
                            }}>
                                Collaboration Options
                            </Typography>
                            <List sx={{ py: 0 }}>
                                {(company.collaboration ).map((item, index) => (
                                    <ListItem key={index} sx={{
                                        justifyContent: "center",
                                        py: 0.5
                                    }}>
                                        <ListItemText
                                            primary={item}
                                            sx={{
                                                textAlign: "center",
                                                color: "text.primary",
                                                '& .MuiTypography-root': {
                                                    fontWeight: 'medium'
                                                }
                                            }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ProfileSlide;