import { configureStore } from '@reduxjs/toolkit';
import { userApi } from './api/userApi';
import { companyApi } from "./api/companyApi";
import { briefingApi } from "./api/briefingApi";
import companyReducer from './redux/slices/companySlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

// Redux Persist Config
const persistConfig = {
    key: 'root',
    storage,
};

const persistedCompanyReducer = persistReducer(persistConfig, companyReducer);

const store = configureStore({
    reducer: {
        [userApi.reducerPath]: userApi.reducer, // Kein Persist für RTK Query
        [companyApi.reducerPath]: companyApi.reducer,
        [briefingApi.reducerPath]: briefingApi.reducer,
        company: persistedCompanyReducer, // Persistiere nur eigene Slices
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignoriere Redux-Persist-Aktionen
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            userApi.middleware,
            companyApi.middleware,
            briefingApi.middleware
        ),
});

export const persistor = persistStore(store);

export default store;