export const dummyData = [
    {
        id: 1,
        name: "The i-engineers AG",
        logo: "/logo-theiengineers.png",
        description: "The i-engineers AG is a\n" +
            "prominent Swiss company\n" +
            "based in Zurich, specializing in\n" +
            "the HealthTech industry. It\n" +
            "focuses on developing\n" +
            "innovative software solutions\n" +
            "aimed at digitalizing hospital\n" +
            "processes and enhancing\n" +
            "healthcare efficiency. The\n" +
            "company is a key player in the\n" +
            "health informatics community,\n" +
            "driving digital transformation\n" +
            "across the healthcare sector.",
        product: "The health-engine® is a modular platform designed to digitize\n" +
            "hospital processes and improve healthcare efficiency. It acts as a\n" +
            "comprehensive clinical data management system, integrating\n" +
            "electronic patient dossiers to enhance data interoperability and\n" +
            "process management.",
        howItWorks:
            "The platform supports semantic interoperability standards and\n" +
            "integrates structured and unstructured data. It utilizes cloud\n" +
            "technologies, including SaaS and PaaS models, and supports\n" +
            "interoperability standards like IHE, HL7, FHIR, and DICOM. This\n" +
            "ensures efficient and secure data exchange and collaboration\n" +
            "among healthcare systems.",
        users: "Hospitals, doctors, and healthcare providers. It is used in over 70\n" +
            "hospitals, including Universitätsspital Basel, to support the digital\n" +
            "transformation of healthcare processes.",
        benefits:
            "By consolidating medical and administrative data, the platform\n" +
            "enhances healthcare delivery, improves operational efficiency, and\n" +
            "facilitates seamless communication and data exchange across\n" +
            "different healthcare providers.",
        collaboration: ["Roche Diagnostics", "Smart-Testing", "Sampletracking", "RIS", "Prozessautomatisierung", "Navify Integrator", "Tumorboard", "Algosuite"],
        deepDive: {
            notes:
                "Together with 14 partners, The i-engineers AG has built an ecosystem to dominate the Swiss market with 300 clinics and over 100,000 users.",
        },
    },
    {
        id: 2,
        name: "DORNER Health IT",
        logo: null,
        description: "A Lab Information Systems provider.",
        product: "Comprehensive laboratory and clinical data integration software.",
        howItWorks:
            "Provides electronic communication, web-based patient portals, and secure laboratory data exchange.",
        users: "Laboratories, hospitals, and medical practices.",
        benefits:
            "Streamlines operations by integrating lab and clinical data, ensuring secure communication and efficient workflows.",
        collaboration: ["RIS", "Sample Tracking", "Prozessautomatisierung"],
        deepDive: null,
    },
    {
        id: 3,
        name: "Better Health Platform",
        logo: null,
        description: "A vendor-neutral health data management platform.",
        product: "Low-code tools for healthcare applications and secure data handling.",
        howItWorks:
            "Supports openEHR and FHIR standards with real-time, event-based data architecture.",
        users: "Healthcare institutions and clinical teams.",
        benefits:
            "Enhances healthcare delivery through consolidated medical data and seamless communication.",
        collaboration: ["FHIR", "EHR Studio", "Event-based Architecture"],
        deepDive: null,
    },
];