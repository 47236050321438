// src/api/userApi.js
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithAuth from './baseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithAuth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        fetchUsers: builder.query({
            query: () => '/api/users/',
            providesTags: ['User'],
        }),
        fetchCurrentUser: builder.query({
            query: () => '/api/users/me',
            providesTags: ['User'],
        }),
        assignRole: builder.mutation({
            query: ({ uuid, roles }) => ({
                url: '/api/users/assign-role',
                method: 'POST',
                body: { uuid, roles },
            }),
            invalidatesTags: ['User'],
        }),
    }),
});

export const { useFetchUsersQuery, useFetchCurrentUserQuery, useAssignRoleMutation } = userApi;