import React, { useState } from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import ProfileSlide from "./ProfileSlide";
import DeepDiveSlide from "./DeepDiveSlide";
import {dummyData} from "./scoutingData";

const ScoutingGallery = () => {
    const [selectedCompany, setSelectedCompany] = useState(dummyData[0]);

    return (
        <Box
            sx={{
                display: "flex",
                height: "100vh",
                bgcolor: "background.default",
                p: 4,
            }}
        >
            {/* Navigation */}
            <Box
                sx={{
                    width: "20%",
                    borderRight: "1px solid #ddd",
                    pr: 2,
                }}
            >
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Firmen
                </Typography>
                <List>
                    {dummyData.map((company) => (
                        <ListItem
                            button
                            key={company.id}
                            onClick={() => setSelectedCompany(company)}
                            selected={selectedCompany.id === company.id}
                        >
                            <ListItemText primary={company.name} />
                        </ListItem>
                    ))}
                </List>
            </Box>

            {/* Firmenprofil */}
            <Box sx={{ flex: 1, p: 4 }}>
                <ProfileSlide company={selectedCompany} />
                {selectedCompany.deepDive && <DeepDiveSlide deepDive={selectedCompany.deepDive} />}
            </Box>
        </Box>
    );
};

export default ScoutingGallery;